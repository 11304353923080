@import '@grain/web-components/src/styles/colors';

.rating-cell,
.manual-approval-cell {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  vertical-align: center;
  font-weight: 500;

  .rating-cell-text {
    width: 100px;
  }

  .toggled-on {
    color: $success;
  }

  .manual-approval-cell-text {
    width: 50px;
  }

  .remove-manual-approval-button {
    .text-button {
      color: black;
    }
  }

  .toggle-on-button {
    color: green;
  }

  .toggle-off-button {
    color: green;
  }
}
